<template>
  <div
    class="max-width-100 d-flex flex-column justify-content-center align-items-start"
  >
    <div class="searchbar position-relative d-flex align-items-center">
      <i class="fas fa-search search-icon"></i>
      <input
        v-model="search"
        type="text"
        placeholder="Masz pytanie? Wprowadź słowo lub frazę by znaleźć rozwiązanie"
      />
      <div class="btn btn-primary" @click="searchForData">Szukaj</div>
    </div>
    <div class="searchbar-outside-info d-flex justify-content-start">
      Popularne frazy: zadania, profil, ustawienia
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "SearchBar",
  props: {
    searchWord: {
      type: String,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.search = this.searchWord || "";
    });
  },
  watch: {
    search: debounce(function (newVal) {
      this.$emit("updateSearch", newVal);
    }, 100),
  },
  methods: {
    searchForData() {
      this.$router.push(`/search-results/${this.search}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.searchbar {
  box-shadow: 0px 8px 64px rgba(82, 120, 185, 0.4);
  border-radius: 16px;
  background: white;
  height: 68px;
  max-width: 100%;
  width: 627px;
  padding: 0 20px;
  input {
    width: 80%;
    margin-right: 20px;
    height: 100%;
    border: 0px;
    outline: none;
    font-weight: 400;
  }
  .search-icon {
    font-size: 16px;
    margin-right: 20px;
  }
}
.searchbar-outside-info {
  font-weight: 400;
  color: #7387ba;
  margin-left: 30px;
  margin-top: 10px;
}
</style>
