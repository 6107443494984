<template>
  <div id="app">
    <transition name="fade">
      <div v-if="$parent.loading" id="site-loader">
        <img src="/img/logo.svg" width="100" alt="Hsys" />
      </div>
    </transition>
    <Navigation />
    <router-view />
    <Footer />
  </div>
</template>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
}
ul {
  padding-left: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
</style>
<script>
import Navigation from "./partials/Navigation";
import Footer from "./partials/Footer";
export default {
  components: { Footer, Navigation },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
  },
};
</script>
