<template>
  <div
    id="footer"
    :style="`${$route.name === 'login' ? 'margin:0 !important' : ''}`"
  >
    <div class="container">
      <div class="row">
        <div
          class="justify-content-center justify-content-lg-start col-12 col-lg-4 d-flex align-items-end"
        >
          <a
            href="Polityka prywatności_HRtec.pdf"
            target="_blank"
            class="text-decoration-underline mr-4"
            >Polityka prywatności</a
          >
        </div>
        <div
          class="mt-4 mt-lg-0 col-12 col-lg-4 d-flex align-items-end justify-content-center"
        >
          2021 HRtec.pl All Rights Reserved
        </div>
        <div
          class="mt-3 mt-lg-0 col-12 col-lg-4 d-flex flex-column align-items-center justify-content-center align-items-md-end justify-content-md-end"
        >
          <div class="d-flex justify-content-between mb-2">
            <a
              href="https://www.facebook.com/HRtec"
              target="_blank"
              class="mr-4"
              style="font-size: 24px"
              ><i class="fab fa-facebook-square"></i
            ></a>
            <a
              href="https://www.linkedin.com/company/hrtec-sp-z-o-o-/"
              class="mr-4"
              target="_blank"
              style="font-size: 24px"
              ><i class="fab fa-linkedin"></i
            ></a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC2zjE-EwCOu1Po0LqmdK4Kw"
              style="font-size: 24px"
              ><i class="fab fa-youtube"></i
            ></a>
          </div>
          <a href="tel:+48553537445" class="mb-2">+48 533 537 445</a>
          <a href="mailto:support@hrtec.pl">support@hrtec.pl</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/colors";

#footer {
  background: $darkBlue2;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
  color: white;

  a {
    color: white;
  }
}
</style>
