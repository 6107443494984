<template>
  <div class="container">
    <h3 class="text-center">Aktualności</h3>
    <p class="mb-5 text-center">
      Najnowsze informacje o zmianach w systemie Hsys
    </p>
    <div class="d-flex justify-content-center">
      <ul
        class="categories-bar d-flex flex-column flex-md-row ml-0 pl-0"
        v-if="categories.length && activeCategory"
      >
        <li
          :class="{ active: activeCategory === category.id }"
          @click="activeCategory = category.id"
          :key="category.id"
          v-for="category in categories"
        >
          {{ category.name }}
        </li>
      </ul>
    </div>
    <div class="news-container">
      <div class="row" v-if="news.length">
        <transition-group
          name="fade"
          tag="div"
          class="col-12 col-md-6 col-xl-3"
          :key="topic.id"
          v-for="topic in news"
        >
          <TopicContainer :key="topic.id" :topic="topic" />
        </transition-group>
      </div>
      <div class="row" v-else>
        <div class="col-12 justify-content-center d-flex">
          <Loader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import forEach from "lodash.foreach";
import Loader from "./Loader";
import TopicContainer from "./TopicContainer";
import _ from "lodash";

export default {
  name: "HomePageNews",
  components: { TopicContainer, Loader },
  data() {
    return {
      categories: [],
      activeCategory: null,
      news: [],
    };
  },
  watch: {
    activeCategory() {
      this.news = [];
      this.getPostsForCategory();
    },
  },
  mounted() {
    this.getCategories();
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
    config() {
      return this.$store.getters.config;
    },
    slug() {
      return this.$route.params.slug;
    },
  },
  methods: {
    getCategories() {
      this.$http
        .get(
          "wp/v2/categories?type=manual_kb&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ",
          this.config ? this.config : null
        )
        .then((response) => {
          this.categories = _.filter(response.data, (category) => {
            if (category.slug !== "tutoriale") {
              return category;
            }
          });
          this.activeCategory = this.categories[0].id;
        });
    },
    getPostsForCategory() {
      this.$http
        .get(
          `wp/v2/posts?categories=${this.activeCategory}&per_page=4&_embed&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
          this.config ? this.config : null
        )
        .then((response) => {
          forEach(response.data, (news, index) => {
            setTimeout(() => {
              news.show = false;
              this.news.push(news);
              setTimeout(() => {
                this.news[index].show = true;
              });
            }, index * 100);
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/colors";

.news-container {
  min-height: 553px;
}

.topic-container {
  height: 553px;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 20px;
  color: $darkBlue;
  box-shadow: 0px 8px 64px rgba(82, 120, 185, 0.2);
  .topic-description {
    overflow: hidden;
    max-height: 59px;
    display: inline-block;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .topic-content {
    padding: 0 24px;
    margin-top: 23px;
  }

  .topic-image {
    height: 300px;
    background-size: cover;
    background-position: center;
  }

  span {
    font-size: 14px;
    color: $purple;
  }

  p {
    font-size: 14px !important;
  }
}

.categories-bar {
  align-items: center;
  border-radius: 16px;
  background: white;
  margin-bottom: 80px;
  box-shadow: 0px 8px 64px rgba(82, 120, 185, 0.2);
  li {
    margin: 0 24px;
    color: $purple;
    font-weight: 400;
    padding: 16px 0;
    cursor: pointer;

    &.active {
      color: $darkBlue;
    }
  }
}
</style>
