<template>
  <div id="navigation" class="no-print">
    <div class="container d-flex justify-content-between">
      <router-link to="/" class="d-flex align-items-center">
        <img src="/img/logo.svg" width="70" alt="" />
      </router-link>
      <div class="d-none d-md-flex">
        <NavigationLinks />
      </div>
      <div
        class="d-flex align-items-center d-md-none cursor-pointer"
        @click="toggleMenu"
      >
        <i class="fas fa-bars"></i>
      </div>
      <div
        class="mobile-menu d-flex align-items-start justify-content-center d-md-none"
        v-if="menuVisible"
      >
        <NavigationLinks />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationLinks from "../components/NavigationLinks";
export default {
  name: "Navigation",
  components: { NavigationLinks },
  data() {
    return {
      menuVisible: false,
    };
  },
  computed: {
    route() {
      return this.$route.name;
    },
  },
  watch: {
    route() {
      this.menuVisible = false;
      window.scrollTo(0, 0);
    },
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>
