<template>
  <div class="d-flex justify-content-center full-width pt-5 pb-5">
    <div class="loader display-inline-block position-static" :style="style">
      <span class="loader-inner w-100 d-inline-block" :style="innerStyle" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: {
    size: {
      type: Number,
      default: 60,
    },
    thickness: {
      type: Number,
      default: 4,
    },
    color: {
      type: String,
      default: "#1C3B6F",
    },
  },
  computed: {
    style() {
      return {
        border: `${this.thickness}px solid ${this.color}`,
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    innerStyle() {
      return { backgroundColor: `${this.color}` };
    },
  },
};
</script>
<style lang="scss" scoped>
.loader {
  animation: loader 2s infinite ease;
  &:before {
    display: none !important;
  }
}

.loader-inner {
  vertical-align: top;
  background-color: #0275d8;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>
